import * as React from 'react'
import PropTypes from 'prop-types'
import Container from './container'
const Footer = () => (
  <div
    style={{
      padding: '8px',
      fontSize: '14px',
      borderTop: '1px solid gray',
    }}
  >
    <Container>
      <div
        style={{
          margin: '0px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <span>FrontendVerse</span>
        <span>Copyright @{new Date().getFullYear()}, FrontendVerse</span>
      </div>
    </Container>
  </div>
)

export default Footer
