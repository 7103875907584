import * as React from 'react'
import PropTypes from 'prop-types'
import Container from './container'
const Header = ({ siteTitle }) => (
  <header
    style={{
      padding: '8px',
      fontWeight: 'bold',
      fontSize: '20px',
    }}
    className="frontendverse-gradient"
  >
    <Container>
      <h3
        style={{
          margin: '0px',
          padding: '10px 0px',
          fontWeight: '800',
          color: 'white',
        }}
      >
        FrontendVerse
      </h3>
    </Container>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
